<template>
  <div class="attachments-page">
    <div class="btn-actions-content">
      <b-button
        v-if="$can('create', permissionModule )"
        class="btn-grad d-flex align-items-center"
        @click="openModal({action: 'create'})"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="attachments-table"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      small
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', permissionModule )"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('/system/upload', 'Upload')"
        />
      </template>
      <template
        v-if="$can('delete', permissionModule )"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: '/system/upload', fetchTable: 'Upload' })"
        />
      </template>
      <template #cell(createdAt)="row">
        {{ row.item.createdAt | formatDateTime }}
      </template>
      <template #cell(relationOrigin)="row">
        {{ row.item.relationOrigin[0] }}
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <!-- TODO:resolver questão de abrir nova aba e exibir pdf -->
          <!-- <b-dropdown-item
            v-if="$can('read', permissionModule )"
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'read'})"
          >
            <VSIcons
              name="Eye"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Ver
            </span>
          </b-dropdown-item>
          <b-dropdown-divider /> -->
          <b-dropdown-item
            v-if="$can('read', permissionModule )"
            class="dropdown-table-item"
            @click="downloadDocument(row.item)"
          >
            <VSIcons
              name="DocumentDownload"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Download
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('update', permissionModule )" />
          <b-dropdown-item
            v-if="$can('update', permissionModule )"
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'edit'})"
          >
            <VSIcons
              name="Edit"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Editar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('delete', permissionModule )" />
          <b-dropdown-item
            v-if="$can('delete', permissionModule )"
            class="dropdown-table-item"
            @click="deleteItem(row.item)"
          >
            <VSIcons
              name="TrashSquare"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Excluir
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="attachments-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      name="UploadFile"
      :properties="properties"
    />
    <VSModal
      width="570"
      :properties="itemToDelete"
      name="DeleteOneItem"
    />
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BDropdownDivider, BInputGroup, BInputGroupPrepend, BFormInput, BTable, BButton, BFormCheckbox, BSpinner, BPagination,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { formatDateTime } from '@core/utils/filter'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'
import upload from '@/services/upload'

export default {
  components: {
    VSModal,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    BButton,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
  },
  filters: {
    formatDateTime,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
    relation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      properties: {
        action: '',
      },
      fields: [
        {
          label: '',
          key: 'select',
          tdClass: 'align-middle',
        },
        {
          label: 'Data',
          key: 'createdAt',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Nome do arquivo',
          key: 'file_name',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Descrição do arquivo',
          key: 'file_description',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Origem da relação',
          key: 'relationOrigin',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Nome da relação',
          key: 'relationName',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Tipo',
          key: 'file_type',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },
      ],
      itemToDelete: { api: '/system/upload', fetchTable: 'Upload' },
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall' }),
    permissionModule() {
      if (this.relation === 'licensingCondition_id') { return 'licensesconditions' }
      if (this.relation === 'agency_id') { return 'licensingagency' }
      return this.relation.split('_')[0]
    },
  },
  created() {
    if (this.permissionModule !== 'licensesconditions' && this.permissionModule !== 'licensing' && this.permissionModule !== 'waste') {
      this.fields = this.fields.filter(arr => arr.key !== 'relationOrigin' && arr.key !== 'relationName')
    }
  },
  methods: {
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
    }),
    openModal(obj) {
      this.properties = obj
      this.properties.relation = this.relation
      this.showModal('UploadFile')
    },
    downloadDocument(row) {
      upload.getLinkDownload(row.file_key).then(res => {
        const fileLink = document.createElement('a')
        fileLink.setAttribute('download', 'file')
        fileLink.setAttribute('href', res.data)
        fileLink.setAttribute('target', '_blank')
        fileLink.click()
      })
    },
  },
}
</script>
