<template>
  <WasteDetails />
</template>

<script>
import WasteDetails from '@/modules/waste/waste-details/WasteDetails.vue'

export default {
  components: { WasteDetails },
}
</script>
