<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
      >
        <WasteDetailsGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab
          title="Inventário"
        >
          <WasteDetailsInventory
            :items="inventoryItems"
          />
        </b-tab>
        <b-tab
          title="Operações"
          :active="getIsOperationsTabActive"
        >
          <WasteDetailsOperations
            :items="operationsItems"
          />
        </b-tab>
        <b-tab>
          <template #title>
            Anexos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ attachmentsWaste.length }}
            </b-badge>
          </template>
          <WasteDetailsAttachments
            :items="attachmentsWaste"
            relation="waste_id"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import WasteDetailsGeneralData from './WasteDetailsGeneralData.vue'
import WasteDetailsAttachments from '@/components/attachments-details/DetailsAttachments.vue'
import WasteDetailsInventory from '@/modules/waste/waste-details/WasteDetailsInventory.vue'
import WasteDetailsOperations from './WasteDetailsOperations.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,
    WasteDetailsGeneralData,
    WasteDetailsInventory,
    WasteDetailsAttachments,
    WasteDetailsOperations,
  },
  computed: {
    ...mapGetters({
      inventoryItems: 'waste/getWasteInventory',
      operationsItems: 'waste/getWasteOperations',
      attachments: 'upload/getUpload',
      getIsOperationsTabActive: 'waste/getIsOperationsTabActive',
    }),
    attachmentsWaste() {
      return this.attachments.filter(arr => arr.waste_id === Number(this.$route.params.id))
    },
  },
  created() {
    this.fetchWasteInventory(this.$route.params.id)
    this.fetchWasteOperation(this.$route.params.id)
    this.fetchUploads()
  },
  methods: {
    ...mapActions({ fetchWasteInventory: 'waste/fetchWasteInventory', fetchWasteOperation: 'waste/fetchWasteOperation', fetchUploads: 'upload/fetchUploads' }),
  },
}
</script>
