<template>
  <div>
    <div
      v-if="$can('update', 'waste')"
      class="btn-actions-content d-flex"
    >
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="cancel()"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges(wasteData)"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <div v-if="!apiInCall">
      <validation-observer ref="form">
        <h6>Dados do Gerador</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="código"
                rules="required"
              >
                <b-form-group label="Código">
                  <b-input-group>
                    <b-form-input
                      id="codigo"
                      v-model="wasteData.waste_code"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-group label="Nome">
                  <b-input-group>
                    <b-form-input
                      id="nome"
                      v-model="wasteData.waste_name"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="empreendimento"
                rules="required"
              >
                <b-form-group label="Empreendimento">
                  <b-input-group>
                    <b-form-select
                      id="empreendimento"
                      v-model="wasteData.project_id"
                      :options="getEnterpriseOptions"
                      :disabled="!edit"
                      @change="fetchEnterpriseData(wasteData.project_id)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group :label="enterpriseData.cnpj ? 'CNPJ' : 'CPF'">
                <b-input-group>
                  <b-form-input
                    id="cnpj"
                    v-mask="enterpriseData.cnpj ? '##.###.###/####-##' : '###.###.###-##'"
                    :value="enterpriseData ? ( enterpriseData.cnpj || enterpriseData.cpf ) : ''"
                    type="text"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <validation-provider
                v-slot="{ errors }"
                name="razão social"
                rules="required|max:50"
              >
                <b-form-group :label="enterpriseData.cnpj ? 'Razão Social' : 'Nome Completo'">
                  <b-input-group>
                    <b-form-input
                      id="razao-social"
                      :value="enterpriseData ? ( enterpriseData.project_name || enterpriseData.full_name ) : ''"
                      type="text"
                      disabled
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="empresa"
                rules="required"
              >
                <b-form-group label="Empresa Vinculada">
                  <b-input-group>
                    <b-form-input
                      id="empresa-vinculada"
                      :value="enterpriseData.company ? (enterpriseData.company.nome_fantasia || enterpriseData.company.full_name) : ''"
                      disabled
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="data de início"
                rules="required"
              >
                <b-form-group label="Data de Início">
                  <b-input-group>
                    <b-form-input
                      id="data-inicio"
                      v-model="wasteData.started_date"
                      type="date"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="gestor principal"
                rules="required"
              >
                <b-form-group label="Gestor Principal">
                  <b-input-group>
                    <b-form-select
                      id="gestor-principal"
                      v-model="wasteData.main_manager_id"
                      :options="usersOptions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <h6>Endereço</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <validation-observer ref="cep">
                <b-form-group label="CEP">
                  <b-input-group>
                    <b-form-input
                      id="cep"
                      :value="enterpriseData.zip_code"
                      disabled
                      type="number"
                    />
                  </b-input-group>
                </b-form-group>
              </validation-observer>
            </b-col>
            <b-col md="2">
              <b-form-group label="Cidade">
                <b-input-group>
                  <b-form-input
                    id="cidade"
                    :value="enterpriseData.city"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="UF">
                <b-input-group>
                  <b-form-select
                    id="uf"
                    :value="enterpriseData.state"
                    :options="ufOptions"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Bairro">
                <b-input-group>
                  <b-form-input
                    id="bairro"
                    :value="enterpriseData.neighborhood"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">

              <b-form-group label="Endereço">
                <b-input-group>
                  <b-form-input
                    id="endereco"
                    :value="enterpriseData.address"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número / Complemento">
                <b-input-group>
                  <b-form-input
                    id="numero"
                    :value="enterpriseData.address_number"
                    disabled
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <h6>Dados da licença</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="2">
              <b-form-group label="Órgão">
                <b-input-group>
                  <b-form-select
                    id="orgao"
                    v-model="wasteData.agency_id"
                    :disabled="!edit"
                    :options="licensingAgencyOptions"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Tipo de Licença">
                <b-input-group>
                  <b-form-select
                    id="tipo-licenca"
                    v-model="wasteData.license_type_id"
                    :options="licenseTypeOptions"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número do processo">
                <b-input-group>
                  <b-form-input
                    id="numero-processo"
                    v-model="wasteData.process_number"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número da licença">
                <b-input-group>
                  <b-form-input
                    id="numero-licenca"
                    v-model="wasteData.license_number"
                    type="text"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data de emissão">
                <b-input-group>
                  <b-form-input
                    id="data-emissao"
                    v-model="wasteData.issue_date"
                    type="date"
                    :disabled="!edit"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data de validade">
                <b-input-group>
                  <b-form-input
                    id="data-validade"
                    v-model="wasteData.expiration_date"
                    :disabled="!edit"
                    type="date"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!--          <b-row>-->
          <!--            <b-col md="2">-->
          <!--              <b-form-group label="Prazo de Conclusão">-->
          <!--                <b-input-group>-->
          <!--                  <b-form-input-->
          <!--                    id="prazo-conclusao"-->
          <!--                    v-model="wasteData.deadline_date"-->
          <!--                    type="number"-->
          <!--                    :disabled="!edit"-->
          <!--                  />-->
          <!--                </b-input-group>-->
          <!--              </b-form-group>-->
          <!--            </b-col>-->
          <!--            <b-col md="2">-->
          <!--              <b-form-group label="Status">-->
          <!--                <b-input-group>-->
          <!--                  <b-form-select-->
          <!--                    id="status"-->
          <!--                    v-model="wasteData.status"-->
          <!--                    :options="statusOptions"-->
          <!--                    :disabled="!edit"-->
          <!--                  />-->
          <!--                </b-input-group>-->
          <!--              </b-form-group>-->
          <!--            </b-col>-->
          <!--          </b-row>-->
        </b-form>
      </validation-observer>
    </div>
    <b-spinner
      v-else
      variant="primary"
      class="general-data-spinner"
    />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BFormSelect, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import waste from '@/services/waste'
import { required } from '@/@core/utils/validations/validations'
import { formatDateTime } from '@/@core/utils/filter'

export default {
  components: {
    BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BFormSelect, BButton, VSIcons, BSpinner,
  },
  filters: {
    formatDateTime,
  },
  data() {
    return {
      required,
      createWaste: false,
      wasteData: {
        waste_code: '',
        waste_name: '',
        started_date: '',
        main_manager_id: null,
        deadline_date: null,
        status: '',
        project_id: null,
        agency_id: null,
        license_type_id: null,
        process_number: '',
        license_number: '',
        issue_date: '',
        expiration_date: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      edit: 'app/getItemTableEdit',
      apiInCall: 'app/getApiInCall',
      sectorOptions: 'sector/getSectorsOptions',
      companiesOptions: 'company/getCompaniesOptions',
      ufOptions: 'app/getUfOptions',
      statusOptions: 'app/getStatusWasteOptions',
      deadlineOptions: 'app/getDeadlineOptions',
      getEnterpriseOptions: 'enterprise/getEnterpriseOptions',
      licensingAgencyOptions: 'publicAgencies/getLicensingAgencyOptions',
      getLicenseTypesOptionsByPublicAgency: 'licenseType/getLicenseTypesOptionsByPublicAgency',
      enterpriseData: 'enterprise/getEnterpriseData',
      processTypeOptions: 'app/getProcessTypeOptions',
      usersOptions: 'users/getUsersOptions',
    }),
    licenseTypeOptions() {
      return this.getLicenseTypesOptionsByPublicAgency(this.wasteData.agency_id)
    },
  },
  created() {
    if (this.verifyRouteAddNewItem()) {
      this.createWaste = true
      this.setSubtitle('')
      this.setDataEnterprise({})
    } else {
      this.fetchWaste(this.$route.params.id)
    }
    this.fetchCompanies()
    this.fetchEnterprises()
    this.fetchLicensingAgencies()
    this.fetchLincenseTypes()
    this.fetchUsers()
  },
  methods: {
    ...mapMutations({
      setEdit: 'app/SET_ITEM_TABLE_EDIT',
      setSubtitle: 'app/SET_SUBTITLE',
      setLicensingData: 'licensing/SET_LICENSING_DATA',
      setWasteData: 'waste/SET_WASTE_DATA',
      setDataEnterprise: 'enterprise/SET_ENTERPRISE_DATA',
    }),
    ...mapActions({
      fetchCompanies: 'company/fetchCompanies',
      fetchEnterprises: 'enterprise/fetchEnterprises',
      fetchLicensingAgencies: 'publicAgencies/fetchLicensingAgencies',
      fetchLincenseTypes: 'licenseType/fetchLincenseTypes',
      fetchEnterpriseData: 'enterprise/fetchEnterpriseById',
      fetchUsers: 'users/fetchUsers',
    }),
    fetchWaste(id) {
      waste.getWasteById(id).then(res => {
        this.wasteData = res.data
        this.setWasteData(res.data)
        this.setSubtitle(this.wasteData.project.project_name)
        this.fetchEnterpriseData(this.wasteData.project.id)
      })
    },
    saveChanges(form) {
      this.$refs.form.validate().then(success => {
        delete form.project
        delete form.licenseType
        delete form.licensingAgency
        delete form.inventory
        delete form.operation
        delete form.main_manager
        if (success) {
          if (this.createWaste) {
            waste.createWaste(form).then(res => {
              this.setEdit(false)
              this.toast({
                title: 'Criado!',
                text: 'Resíduos criado com sucesso!',
                variant: 'success',
              })
              this.$router.push(`/residuos/${res.data.id}/detalhes`)
            })
          } else {
            waste.editWaste(form).then(() => {
              this.setEdit(false)
              this.toast({
                title: 'Atualizado!',
                text: 'Resíduos atualizado com sucesso!',
                variant: 'success',
              })
              this.fetchWaste(this.$route.params.id)
            })
          }
          this.setSubtitle(this.wasteData.project.project_name)
        }
      })
    },

    cancel() {
      this.setEdit(false)
      if (this.createWaste) {
        this.$router.push({ name: 'waste' })
      } else {
        this.fetchWaste(this.$route.params.id)
      }
    },

    getAddress(cep) {
      this.$refs.cep.validate().then(success => {
        if (success) {
          const address = this.getAdressByCep(cep)
          this.wasteData.address = address.logradouro
          this.wasteData.neighborhood = address.bairro
          this.wasteData.state = address.uf
          this.wasteData.city = address.localidade
          this.wasteData.address_number = address.complemento
        }
      })
    },
  },
}
</script>
