<template>
  <div class="enterprise-page">
    <div
      v-if="$can('create', 'waste')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="openModal({action: 'create'})"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <b-input-group class="input-group-merge round search-input-filter">
      <b-input-group-prepend
        is-text
      >
        <VSIcons
          name="Search"
          color="#7D8E9E"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        id="filter-input"
        v-model="filter"
        placeholder="Buscar..."
        type="search"
      />
    </b-input-group>
    <b-table
      id="enterprise-table"
      ref="enterpriseTable"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'waste')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/waste/inventory', 'WasteInventory')"
        />
      </template>
      <template
        v-if="$can('delete', 'waste')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/waste/inventory', fetchTable: 'WasteInventory' })"
        />
      </template>
      <template #cell(addItemInventory)="row">
        {{ row.item.operation.filter(arr => arr.isActive).length }}
        <VSIcons
          class="ml-1"
          name="BriefcaseFillGradient"
        />
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <b-dropdown-item
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'read'})"
          >
            <VSIcons
              name="Eye"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Ver
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('update', 'waste')" />
          <b-dropdown-item
            v-if="$can('update', 'waste')"
            class="dropdown-table-item"
            @click="uploadFile(row.item)"
          >
            <VSIcons
              name="PaperClip"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Anexar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('update', 'waste')" />
          <b-dropdown-item
            v-if="$can('update', 'waste')"
            class="dropdown-table-item"
            @click="openModal({item: row.item, action: 'edit'})"
          >
            <VSIcons
              name="Edit"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Editar
            </span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="$can('delete', 'waste')" />
          <b-dropdown-item
            v-if="$can('delete', 'waste')"
            class="dropdown-table-item"
            @click="deleteItem(row.item)"
          >
            <VSIcons
              name="TrashSquare"
              width="21"
              height="21"
              color="#7D8E9E"
            />
            <span>
              Excluir
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="enterprise-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      ref="inventoryModal"
      name="Inventory"
      :properties="properties"
      width="970"
      :scrollable="true"
      :open-modal-search-code="openModalSearchCode"
    />
    <VSModal
      width="570"
      :properties="itemToDelete"
      name="DeleteOneItem"
    />
    <VSModal
      name="UploadFile"
      :properties="properties"
    />
    <VSModal
      name="SearchCode"
      width="970"
      :send-waste-data="sendWasteData"
    />
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput, BTable,
  BButton,
  BFormCheckbox,
  BSpinner,
  BPagination,
  BDropdownItem,
  BDropdown,
  BDropdownDivider,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTable,
    BButton,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    VSIcons,
    VSModal,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      properties: { action: '', item: {} },
      itemToDelete: { api: '/system/waste/inventory', fetchTable: 'WasteInventory' },
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Cód Interno',
          key: 'internal_code',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Nome',
          key: 'name',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Descrição',
          key: 'description',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Classificação',
          key: 'classification',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Estado físico',
          key: 'physical_state',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Armazenamento',
          key: 'storage',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Acondicionamento',
          key: 'conditioning',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Cód. Resíduo',
          key: 'residue_code',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'addItemInventory',
          tdClass: 'd-flex j-c-center',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle p-0 pr-4',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall' }),
  },
  methods: {
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    openModal(item) {
      this.properties = item
      this.properties.module = this.module
      this.showModal('Inventory')
    },
    openModalSearchCode() {
      this.showModal('SearchCode')
    },
    uploadFile(row) {
      this.properties.action = 'create'
      this.properties.relations = [{ name: 'inventory_id', id: row.id }]
      this.properties.relations.push({ name: 'waste_id', id: this.$route.params.id })
      this.showModal('UploadFile')
    },
    sendWasteData(data) {
      this.hideModal('SearchCode')
      this.$refs.inventoryModal.receiveWasteData(data)
    },
  },
}
</script>
